<template>
  <div id="shopping-cart">
    <el-steps :active="active" finish-status="success" :align-center="true">
      <el-step title="我的购物车"></el-step>
      <el-step title="确认订单信息"></el-step>
      <el-step title="成功提交订单"></el-step>
    </el-steps>
    <div class="my-cart">
      <h2>
        我的购物车<span
          style="color: var(--subjectColor); font-size: 18px; font-weight: bold"
          >（{{ allSum }}）</span
        >
      </h2>
      <div class="title">
        <p>货品</p>
        <p>单价（元）</p>
        <p>数量</p>
        <p>小计（元）</p>
        <p>操作</p>
      </div>
      <div class="store" v-for="(list, index) in store" :key="index">
        <div class="store-name">
          <label
            ><input
              type="checkbox"
              @click="dealSelectShop(index)"
              :checked="list.isAllSelect"
          /></label>
          <p
            @click="
              goStore(
                list.orderCardListItems[0].storeId,
                list.orderCardListItems[0].enterpriseId
              )
            "
          >
            {{ list.storeName }}
          </p>
        </div>
        <div
          class="goods"
          v-for="(goods, subindex) in list.orderCardListItems"
          :key="subindex"
          :style="goods.isSelect ? ' background: #fff7ee;' : ''"
        >
          <label
            ><input
              type="checkbox"
              :checked="goods.isSelect"
              @click="dealSelectGoods(index, subindex)"
          /></label>
          <img v-lazy="goods.filePath" style="width: 84px; height: 84px" />
          <div class="details">
            <div>
              <span>品名：</span>
              <h3
                :title="goods.commodityName"
                @click="
                  goGoods(goods.storeId, goods.barcode, goods.enterpriseId)
                "
              >
                {{ goods.commodityName }}
              </h3>
            </div>
            <p>
              品牌：<span>{{ goods.brandName }}</span>
            </p>
            <p>
              规格：<span>{{ goods.specsParameter }}</span>
            </p>
          </div>
          <div class="unit-price">￥{{ goods.price | price }}</div>
          <div class="input-number">
            <el-input-number
              size="mini"
              v-model="goods.quantity"
              :min="
                goods.conditionSetting === 2 ? goods.tradeMinimumQuantity : 1
              "
              :step="goods.multiple ? goods.tradeMinimumQuantity : 1"
              @change="quantityChange(goods)"
            >
            </el-input-number>
            <p v-if="goods.conditionSetting === 0">（无价格限制）</p>
            <p v-else-if="goods.conditionSetting === 2">
              （起订量≥{{ goods.tradeMinimumQuantity }}瓶<span
                v-if="goods.multiple"
                >，整倍订货</span
              >）
            </p>
          </div>

          <div class="sum">
            <p>￥{{ (goods.price * goods.quantity) | price }}</p>
            <p class="minimum" v-if="goods.conditionSetting === 1">
              （起订价≥￥{{ goods.tradeStartingPrice | price }} ）
            </p>
          </div>
          <div class="delete"><p @click="deleteGoods([goods.id])">删除</p></div>
        </div>
      </div>
      <el-empty description=" " v-if="store == ''" :image="img">
        <p>购物车空空的哦，去<a href="/">首页</a>逛逛~~</p>
      </el-empty>
      <div class="settlement" v-if="store != ''">
        <div class="left">
          <input type="checkbox" @click="selectAll" :checked="checkAll" />
          <p class="delete" @click="deleteSelectGoods">删除选中商品</p>
          <p class="failure">清除失效商品</p>
        </div>
        <div class="right">
          <div class="selected">
            <p>
              已选货品种类：<span>{{ allSpecies }}</span
              >&nbsp;种
            </p>
          </div>
          <div class="number">
            <p>
              数量总计：<span>{{ allNum }}</span
              >&nbsp;件
            </p>
          </div>
          <div class="total-price">
            <p>
              货品金额合计（不含运费）:&nbsp;<span>{{ allPrice | price }}</span
              >&nbsp;元
            </p>
          </div>
          <button
            :style="[
              { background: allNum === 0 ? '#BBB' : '' },
              { cursor: allNum === 0 ? '' : 'pointer' },
            ]"
            @click="goConfirmAnOrder"
          >
            结&nbsp;算
          </button>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <p><svg-icon icon-class="ic_Tips" />因商城业务调整，暂不支持下单，请查看公告。</p>
      <!-- <p><svg-icon icon-class="ic_Tips" />店长请使用PDA终端开展店长业务。</p> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Cookies from "js-cookie";
import { mapState } from "vuex";
import { decimals } from "@/utils/validate";

export default {
  name: "shopping-cart",
  data() {
    return {
      active: 0,
      store: "", //
      // isSelectAll: false,
      checkAll: false,
      checkedCities: [],
      allSum: 0,
      img: require("@/assets/images/EmptyCart.png"),
      dialogVisible: false,
    };
  },
  // computed: {
  //   ...mapState("personalUser", ["userInfo"]),
  // },
  computed: {
    ...mapState("personalUser", ["userInfo"]),
    allSpecies() {
      var allSpecies = 0;
      for (let shop of this.store) {
        for (let goods of shop.orderCardListItems) {
          if (goods.isSelect) {
            allSpecies += 1;
          }
        }
      }
      return allSpecies;
    },
    allPrice() {
      var allPrice = 0;
      for (let shop of this.store) {
        for (let goods of shop.orderCardListItems) {
          if (goods.isSelect) {
            allPrice += goods.quantity * goods.price;
          }
        }
      }
      return allPrice;
    },
    allNum() {
      var allNum = 0;
      for (let shop of this.store) {
        for (let goods of shop.orderCardListItems) {
          if (goods.isSelect) {
            allNum += goods.quantity;
          }
        }
      }
      return allNum;
    },
  },
  watch: {
    store: {
      handler: function (val, oldVal) {
        this.checkedCities = [];
        this.allSum = 0;
        for (const list of val) {
          for (const item of list.orderCardListItems) {
            this.allSum += item.quantity;
          }
        }
        this.$store.commit("personalUser/getCartSum", this.allSum);

        for (const store of val) {
          if (store.isSelect) {
            this.checkedCities.push(store);
          }
        }
        // console.log(this.checkedCities);
      },
      deep: true,
    },
  },
  filters: {
    //处理函数
    price(num) {
      return decimals(num);
      // console.log(value);
      // return value + ".00";
      // console.log(value.toString().split(".")[1].length);
    },
    quantity(val) {
      console.log(val);
    },
  },
  created() {
    let i = 0;
    this.t = setInterval(() => {
      i++;
      if (sessionStorage.getItem("userId")) {
        this.getEnterpriseId();
        clearInterval(this.t);
      } else if (i > 10) {
        clearInterval(this.t);
      }
    }, 100);
  },

  methods: {
    //购物车列表
    async getEnterpriseId() {
      const data = await this.$API.personalUser.getShoppingCartList({
        object: {
          userId: sessionStorage.getItem("userId"),
        },
        pageNum: 0,
        pageSize: 0,
      });
      let store = data.data.data;
      let now = new Date();
      let m = now.getTime(); //当前时间戳
      // console.log(store);

      // this.PriceDetails.conditionSetting = 2;
      // this.PriceDetails.tradeMinimumQuantity = 5;
      // this.PriceDetails.multiple = 1;

      for (const list of store) {
        list.isAllSelect = false;
        list.isSelect = false;
        list.message = "";
        list.messageSelected = false;
        for (const item of list.orderCardListItems) {
          item.isSelect = false;
          item.filePath = item.filePath.split(",")[0];
          // this.allSum += item.quantity;
          let now = new Date();
          let m = now.getTime();
          item.preferentialTime = false;
          if (item.preferentialStartTime < m && m < item.preferentialEndTime) {
            item.preferentialTime = true;
          } else {
            item.preferentialTime = false;
          }
          if (item.preferentialTime) {
            if (this.userInfo.userEntity.userType === "PURCHASE") {
              item.price = item.preferentialTradePrice;
            } else {
              item.price = item.preferentialRetailPrice;
            }
          } else {
            if (this.userInfo.userEntity.userType === "PURCHASE") {
              item.price = item.tradePrice;
            } else {
              item.price = item.retailPrice;
            }
          }
        }
      }

      this.store = store;
      // console.log(store);
    },
    async quantityChange(goods) {
      if (goods.preferentialTime) {
        if (goods.preferentialConditionSetting === 2) {
          goods.quantity === undefined &&
            (goods.quantity = goods.ptradeMinimumQuantity);
          if (
            goods.multiple === 1 &&
            !Number.isInteger(goods.quantity / goods.ptradeMinimumQuantity)
          ) {
            this.$message.error("需整倍订货");
            goods.quantity = goods.ptradeMinimumQuantity;
          }
        } else if (goods.preferentialConditionSetting === 1) {
          if (
            goods.quantity <
            Math.floor(goods.ptradeStartingPrice / goods.price) + 1
          ) {
            this.$message.error("购买数量不能低于特惠价");
          } else {
            goods.quantity =
              Math.floor(goods.ptradeStartingPrice / goods.price) + 1;
          }
        }
      } else if (!goods.preferentialTime) {
        if (goods.conditionSetting === 2) {
          goods.quantity === undefined &&
            (goods.quantity = goods.tradeMinimumQuantity);
          if (
            goods.multiple === 1 &&
            !Number.isInteger(goods.quantity / goods.tradeMinimumQuantity)
          ) {
            this.$message.error("需整倍订货");
            goods.quantity = goods.tradeMinimumQuantity;
          }
        } else if (goods.conditionSetting === 1) {
          if (
            goods.quantity <
            Math.floor(
              goods.PriceDetails.tradeStartingPrice / goods.PriceDetails.price
            ) +
              1
          ) {
            this.$message.error("购买数量不能低于特惠价");
          } else {
            goods.quantity =
              Math.floor(
                goods.PriceDetails.tradeStartingPrice / goods.PriceDetails.price
              ) + 1;
          }
        }
      } else {
        goods.quantity === undefined && (goods.quantity = "1");
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        const data = this.$API.personalUser.UpdateOrderCart({
          object: {
            id: goods.id,
            quantity: goods.quantity,
          },
        });
      }, 500);
    },
    //选店铺
    dealSelectShop(index) {
      // console.log(index);
      let shop = this.store[index];
      shop.isAllSelect = !shop.isAllSelect;
      shop.isSelect = !shop.isSelect;
      for (let list of shop.orderCardListItems) {
        list.isSelect = shop.isAllSelect;
      }

      //判断所有商铺是否被选中
      let isSelectAllShop = true;
      for (let shop of this.store) {
        if (shop.isAllSelect == false) {
          isSelectAllShop = false;
        }
      }

      if (isSelectAllShop) {
        // this.isSelectAll = true;
        this.checkAll = true;
      } else {
        // this.isSelectAll = false;
        this.checkAll = false;
      }
    },
    //选商品
    dealSelectGoods(index, subindex) {
      let shop = this.store[index];
      let goods = shop.orderCardListItems[subindex];
      goods.isSelect = !goods.isSelect;
      shop.isSelect = false;

      //判断所有商品是否被选中
      let isSelectAllGoods = true;
      for (let goods of shop.orderCardListItems) {
        if (goods.isSelect == false) {
          isSelectAllGoods = false;
        } else {
          shop.isSelect = true;
        }
      }

      if (isSelectAllGoods) {
        shop.isAllSelect = true;
      } else {
        shop.isAllSelect = false;
      }
      //判断所有商铺是否被选中
      let isSelectAllShop = true;
      for (let shop of this.store) {
        if (shop.isAllSelect == false) {
          isSelectAllShop = false;
        }
      }

      if (isSelectAllShop) {
        // this.isSelectAll = true;
        this.checkAll = true;
      } else {
        // this.isSelectAll = false;
        this.checkAll = false;
      }
    },
    //全选
    selectAll() {
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        for (let list of this.store) {
          list.isAllSelect = true;
          list.isSelect = true;
          for (let item of list.orderCardListItems) {
            item.isSelect = true;
          }
        }
      } else {
        for (let list of this.store) {
          list.isAllSelect = false;
          list.isSelect = false;
          for (let item of list.orderCardListItems) {
            item.isSelect = false;
          }
        }
      }
    },
    //结算
    goConfirmAnOrder() {
      if (this.checkedCities.length) {
        // this.dialogVisible = true;
        // return;
        let b = JSON.stringify(this.checkedCities);
        let a = JSON.parse(b);
        for (let i = 0; i < a.length; i++) {
          a[i].allNum = 0;
          for (let idx = 0; idx < a[i].orderCardListItems.length; idx++) {
            if (!a[i].orderCardListItems[idx].isSelect) {
              a[i].orderCardListItems.splice(idx, 1);
              idx--;
            } else {
              a[i].allNum +=
                a[i].orderCardListItems[idx].price *
                a[i].orderCardListItems[idx].quantity;
            }
          }
        }

        sessionStorage.setItem("goods", JSON.stringify(a));
        this.$router.push({
          path: `ConfirmAnOrder`,
        });
      }
      // let index = 0;

      // for (let i = 0; i < this.store.length; i++) {
      //   this.store[i].isSelect && index++;
      // }
      // if (index === 1) {
      //   sessionStorage.setItem("goods", JSON.stringify(this.checkedCities));
      //   this.$router.push({
      //     path: `ConfirmAnOrder`,
      //   });
      // } else if (index > 1) {
      //   this.$message.error("一次只能结算一个店铺订单");
      // }
    },
    //删除商品
    deleteGoods(goodsId) {
      this.$confirm("您确定要删除选中商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .DeleteOrderCart({
              object: {
                id: goodsId,
              },
            })
            .then((data) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
          this.getEnterpriseId();
          this.checkAll = false;
        })
        .catch(() => {
          this.$message({
            message: "取消成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        });
    },
    //删除选中商品
    deleteSelectGoods() {
      if (this.allNum === 0) {
        this.$message("请选择要删除的商品");
      } else {
        let id = [];
        for (const list of this.store) {
          if (list.isSelect === true) {
            for (const item of list.orderCardListItems) {
              id.push(item.id);
            }
          } else {
            for (const item of list.orderCardListItems) {
              if (item.isSelect === true) {
                id.push(item.id);
              }
            }
          }
        }
        this.deleteGoods(id);
      }
    },

    goStore(storeId, id) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${storeId}`,
        query: { enterpriseId: id },
      });
      window.open(a.href, "_blank");
    },
    goGoods(storeId, barcode, id) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${storeId}/GoodsDetails/${barcode}`,
        query: { enterpriseId: id },
      });
      window.open(a.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
#shopping-cart {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid var(--subjectColor);
  padding-bottom: 60px;

  ::v-deep .el-steps {
    width: 520px;
    padding: 30px 0;
    .el-step__head {
      .el-step__icon {
        width: 26px;
        height: 26px;
        .el-step__icon-inner {
          font-weight: 400;
        }
        i::before {
          color: #00c45c;
          font-weight: 700;
        }
      }
    }
    .el-step__main {
      .is-success {
        color: #00c45c;
      }
      .el-step__title {
        font-size: 14px;
      }
    }
    .is-success {
      border-color: #00c45c;
    }
    .is-process {
      color: #00c45c;
      border-color: #00c45c;
      .is-text {
        background: #00c45c;
        color: #fff;
      }
    }
  }

  .my-cart {
    width: 1200px;
    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      color: #333333;
      margin-bottom: 20px;
    }
    .title {
      display: flex;
      height: 46px;
      background: #f5f5f5;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-weight: 400;
      line-height: 44px;
      color: #333333;
      padding: 0 76px 0 40px;
      p:nth-child(1) {
        margin-right: 403px;
      }
      p:nth-child(2) {
        margin-right: 174px;
      }
      p:nth-child(3) {
        margin-right: 162px;
      }
      p:nth-child(4) {
        margin-right: 115px;
      }
    }
    .store {
      label {
        height: 16px;
        width: 16px;
      }
      input[type="checkbox"] {
        position: relative;
        height: 16px;
        width: 16px;
        float: left;
        margin: 0;
        // margin-right: 5px;
        cursor: pointer;
      }

      input[type="checkbox"]::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        line-height: 14px;
        text-align: center;
        color: white;
        font-size: 14px;
        background-color: #fff;
        border: 1px solid #999999;
      }

      input[type="checkbox"]:checked::before {
        color: #fe3132;
        background-color: #fff;
        border: 1px solid #fe3132;
        content: "\2713";
      }
      // ::v-deep .el-checkbox {
      //   .is-indeterminate .el-checkbox__inner {
      //     background: #fff;
      //   }
      //   .is-indeterminate .el-checkbox__inner::before {
      //     top: 6px;
      //     background-color: #fe3132;
      //   }
      //   .el-checkbox__label {
      //     display: none;
      //   }
      //   .is-checked .el-checkbox__inner {
      //     background: #fff;
      //     border: 1px solid #fe3132;
      //   }
      //   .el-checkbox__inner {
      //     width: 16px;
      //     height: 16px;
      //     border: 1px solid #999999;
      //   }
      //   .el-checkbox__inner::after {
      //     left: 5px;
      //     top: 2px;
      //     border: 1px solid #fe3132;
      //     border-top: 0;
      //     border-left: 0;
      //   }
      // }
      // ::v-deep .el-checkbox-group {
      //   display: flex;
      //   flex-direction: column;
      // }
      // ::v-deep > .el-checkbox:nth-child(1) {
      //   height: 24px;
      //   margin: 24px 0 12px 0;
      //   display: flex;
      //   padding-left: 12px;

      //   .el-checkbox__input {
      //     line-height: 2;
      //   }
      //   .el-checkbox__label {
      //     line-height: 22px;
      //     font-size: 16px;
      //     font-weight: 400;
      //     color: #333333;
      //     display: block;
      //   }
      // }
      .store-name {
        display: flex;
        height: 22px;
        align-items: center;
        margin: 20px 0 12px 0;

        line-height: 22px;
        color: #333333;
        label {
          margin: 0 12px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
        p:hover {
          color: var(--subjectColor);
        }
      }
      .goods {
        display: flex;
        padding: 20px 12px;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        position: relative;
        label {
          margin-right: 12px;
        }
        ::v-deep .el-checkbox {
          height: 18px;
          margin-right: 12px;
        }

        img {
          margin-right: 14px;
        }
        .details {
          width: 286px;
          padding-right: 20px;
          // cursor: default;
          div {
            display: flex;
            margin-bottom: 15px;
            h3 {
              flex: 1;
              height: 21px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }
            h3:hover {
              color: var(--subjectColor);
            }
          }
          p {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            margin-bottom: 8px;
            span {
              color: #666666;
            }
          }
        }
        .input-number {
          display: flex;
          flex-direction: column;
          text-align: center;
          ::v-deep .el-input-number {
            margin: 0 90px;
            .el-input__inner:focus {
              border-color: none;
            }
            span {
              height: 28px;
              .el-icon-plus:before {
                font-weight: 900;
              }
            }
            span:hover {
              border-color: #dcdfe6;
            }
            .el-input input {
              height: 30px;
              line-height: 30px;
              border-radius: 0;
            }
          }
          > p {
            margin-top: 6px;
            font-size: 14px;
            color: var(--subjectColor);
          }
        }

        .unit-price {
          text-align: center;
          width: 150px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .sum {
          width: 106px;
          text-align: center;
          position: relative;
          p {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
          .minimum {
            position: absolute;
            width: 200px;
            left: -50%;
            margin-top: 6px;
            font-weight: normal;
            color: var(--subjectColor);
          }
        }
        .delete {
          margin-left: 98px;
          font-size: 14px;
          p {
            cursor: pointer;
          }
          p:hover {
            color: #fe3132;
          }
        }
      }
      .goods:nth-child(2) {
        border-top: 3px solid #ffa23d;
      }
    }
    .el-empty {
      padding: 100px 0;
      .el-empty__bottom p {
        font-size: 16px;
        color: #333333;
        a {
          color: #086ac2;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .settlement {
      width: 1200px;
      height: 56px;
      background: rgba(248, 248, 248, 0.39);
      border: 1px solid #ededed;
      display: flex;
      align-items: center;
      padding-left: 12px;
      margin-top: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          position: relative;
          height: 16px;
          width: 16px;
          margin: 0;
          // margin-right: 5px;
          cursor: pointer;
        }

        input[type="checkbox"]::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 14px;
          width: 14px;
          line-height: 14px;
          text-align: center;
          color: white;
          font-size: 14px;
          background-color: #fff;
          border: 1px solid #999999;
        }

        input[type="checkbox"]:checked::before {
          color: #fe3132;
          background-color: #fff;
          border: 1px solid #fe3132;
          content: "\2713";
        }
        .delete {
          margin: 0 30px 0 10px;
          cursor: pointer;
        }
        .delete:hover {
          color: #ff0000;
        }
        .failure:hover {
          color: #ff0000;
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        align-items: center;
        div {
          margin: 0 12px;
        }
        div span {
          color: #fe3132;
          font-size: 20px;
        }
        button {
          width: 140px;
          height: 56px;
          background: var(--subjectColor);
          border: none;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  ::v-deep .el-dialog__wrapper {
    .el-dialog__header {
      padding: 12px 20px;
    }
    .el-dialog__body {
      padding-top: 0;

      .svg-icon {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
}
</style>